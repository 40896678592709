

export const getRegulationOptions = (data: any, brands: string[]) => {
    return [
        'All',
      ...new Set(
        data?.dropdownData
          .filter((record: any) => brands.includes(record.brand))
          .map((record: any) => record.regulation),
      ),
    ];
  };
  
  export const handleRegulationDependencies = (
    filters: any,
    setFilters: any,
    getTypeApprovalOptions: any,
    getRxsWinOptions: any,
    newValue: string[]
  ) => {
    const newFilters = {
      typeApprovals:
        filters.typeApprovals &&
        !getTypeApprovalOptions(filters.brand, newValue).includes(filters.typeApprovals)
          ? ''
          : filters.typeApprovals,
      rxsWin:
        filters.rxsWin && !getRxsWinOptions(filters.brand, newValue).includes(filters.rxsWin)
          ? ''
          : filters.rxsWin,
    };
  
    setFilters((prevState: any) => ({
      ...prevState,
      ...newFilters,
    }));
  };