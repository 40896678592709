import { useEffect, useRef, useState } from 'react';
import { useTheme, Box } from '@mui/material';
import { FilterListOff } from '@mui/icons-material';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  StatusPanelDef,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import {
  LoadingOverlay,
  NoRowsOverlay,
  StyledClearFilterButton,
} from '~components';
import { useNavigate } from 'react-router-dom';

interface BaseDataGridProps {
  formattedColumnDefs: ColDef[];
  formattedRowData?: Record<string, string>[] | null;
  loading: boolean;
  groupByValue?: string;
  autoGroupColumnDef?: ColDef;
  statusPanels: StatusPanelDef[];
  onSelectionChanged?: (gridApi: GridApi) => void;
  tooltipComponentvalue: any
  tooltipMouseTrackValue: boolean
  flexValue: number
  resizeColumns: boolean
}

export const BaseDataGrid = ({
  formattedColumnDefs,
  formattedRowData,
  loading,
  groupByValue,
  autoGroupColumnDef,
  statusPanels,
  onSelectionChanged,
  tooltipComponentvalue,
  tooltipMouseTrackValue,
  flexValue,
  resizeColumns,

}: BaseDataGridProps) => {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [gridApi, setGridApi] = useState<GridApi | null | undefined>();
  const [columnApi, setColumnApi] = useState<ColumnApi | null | undefined>();
  const [currentGridFilters, setCurrentGridFilters] = useState({});
  const gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      flex: flexValue,
      menuTabs: ['filterMenuTab'],
      tooltipComponent: tooltipComponentvalue,
      wrapText: true,
      autoHeight: true,
    },
  };

  const onGridReady = (params: GridOptions) => {
    setColumnApi(params.columnApi);
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi && onSelectionChanged) {
      onSelectionChanged(gridApi);
    }
  }, [gridApi, onSelectionChanged]);

  const onFilterChangedHandler = () => {
    if (gridApi) setCurrentGridFilters(gridApi.getFilterModel());
  };

  const columnResizeHandler = () => {
    if (resizeColumns) {
      setTimeout(() => {
        columnApi?.autoSizeAllColumns();
      }, 200);
    }
  };

  useEffect(() => {
    if (columnApi) {
      columnResizeHandler();
    }
  }, [groupByValue, columnApi]);

  useEffect(() => {
    if (loading) {
      gridApi?.showLoadingOverlay();
    } else if (formattedRowData && !formattedRowData.length) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [loading, gridApi]);

  return (
    <Box
      className="ag-theme-alpine"
      sx={{
        height: '100%',
        minHeight: '500px',
        width: '100%',
        position: 'relative',
        padding: {
          xs: `${theme.spacing(2)}`,
          sm: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
        },
      }}
    >
      {Object.keys(currentGridFilters).length ? (
        <StyledClearFilterButton gridApi={gridApi}>
          <FilterListOff />
        </StyledClearFilterButton>
      ) : undefined}
      <AgGridReact
        ref={gridRef}
        autoGroupColumnDef={autoGroupColumnDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        columnDefs={formattedColumnDefs}
        rowData={formattedRowData}
        statusBar={{ statusPanels }}
        groupDisplayType="multipleColumns"
        noRowsOverlayComponent={NoRowsOverlay}
        loadingOverlayComponent={LoadingOverlay}
        noRowsOverlayComponentParams={{
          loading,
        }}
        onFilterChanged={onFilterChangedHandler}
        onSelectionChanged={() => onSelectionChanged && onSelectionChanged(gridApi!)}
        tooltipShowDelay={500}
        tooltipMouseTrack={tooltipMouseTrackValue}
        animateRows
        rowSelection="single"
        onExpandOrCollapseAll={columnResizeHandler}
        onRowDataUpdated={columnResizeHandler}
        onRowGroupOpened={columnResizeHandler}
      />
    </Box>
  );
};