import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
};

export enum AccessLevel {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
  NoValidRole = 'NO_VALID_ROLE'
}

export type AccessLevelOperationFilterInput = {
  eq?: InputMaybe<AccessLevel>;
  in?: InputMaybe<Array<AccessLevel>>;
  neq?: InputMaybe<AccessLevel>;
  nin?: InputMaybe<Array<AccessLevel>>;
};

export type AddBaselineInput = {
  baselineId: Scalars['String'];
  certificationChanged: Scalars['Boolean'];
  putInQueueBefore: Scalars['Boolean'];
  regulationsFreezed: Scalars['Boolean'];
};

export type AddRxsWinInput = {
  approvalNumberInformation: ApprovalNumberInformationInput;
  brand: Scalars['String'];
  market: Scalars['String'];
  regulationNumber: Scalars['String'];
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export type ApprovalNumberInformationInput = {
  approvalNumber: Scalars['String'];
  authority: Scalars['String'];
  directive: Scalars['String'];
  extension: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type Approver = {
  __typename?: 'Approver';
  name: Scalars['String'];
};

export type AuditableRegistryRow = {
  __typename?: 'AuditableRegistryRow';
  approvalNumber: Scalars['String'];
  baselineName?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  checksumMethod?: Maybe<Scalars['String']>;
  complianceAssessmentReport?: Maybe<Scalars['String']>;
  consumerSoftwareVersion?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  ecuCorePartNumber: Scalars['String'];
  ecuCoreSupplierPartNumber?: Maybe<Scalars['String']>;
  ecuHomologationNumber?: Maybe<Scalars['String']>;
  ecuPartNumber?: Maybe<Scalars['String']>;
  ecuShortName: Scalars['String'];
  kdpPartType?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  regulation: Scalars['String'];
  rxsWin: Scalars['String'];
  softwarePackageName?: Maybe<Scalars['String']>;
  softwarePackageNameId?: Maybe<Scalars['String']>;
  softwarePartNumber: Scalars['String'];
  softwareSupplierPartNumber?: Maybe<Scalars['String']>;
  softwareType?: Maybe<Scalars['String']>;
  systemDescription?: Maybe<Scalars['String']>;
  vbfChecksum?: Maybe<Scalars['String']>;
  vehicleSpec?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type AuditableRegistryRowFilterInput = {
  and?: InputMaybe<Array<AuditableRegistryRowFilterInput>>;
  approvalNumber?: InputMaybe<StringOperationFilterInput>;
  baselineName?: InputMaybe<StringOperationFilterInput>;
  brand?: InputMaybe<StringOperationFilterInput>;
  checksumMethod?: InputMaybe<StringOperationFilterInput>;
  complianceAssessmentReport?: InputMaybe<StringOperationFilterInput>;
  consumerSoftwareVersion?: InputMaybe<StringOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  ecuCorePartNumber?: InputMaybe<StringOperationFilterInput>;
  ecuCoreSupplierPartNumber?: InputMaybe<StringOperationFilterInput>;
  ecuHomologationNumber?: InputMaybe<StringOperationFilterInput>;
  ecuPartNumber?: InputMaybe<StringOperationFilterInput>;
  ecuShortName?: InputMaybe<StringOperationFilterInput>;
  kdpPartType?: InputMaybe<StringOperationFilterInput>;
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AuditableRegistryRowFilterInput>>;
  regulation?: InputMaybe<StringOperationFilterInput>;
  rxsWin?: InputMaybe<StringOperationFilterInput>;
  softwarePackageName?: InputMaybe<StringOperationFilterInput>;
  softwarePackageNameId?: InputMaybe<StringOperationFilterInput>;
  softwarePartNumber?: InputMaybe<StringOperationFilterInput>;
  softwareSupplierPartNumber?: InputMaybe<StringOperationFilterInput>;
  softwareType?: InputMaybe<StringOperationFilterInput>;
  systemDescription?: InputMaybe<StringOperationFilterInput>;
  vbfChecksum?: InputMaybe<StringOperationFilterInput>;
  vehicleSpec?: InputMaybe<StringOperationFilterInput>;
  version?: InputMaybe<StringOperationFilterInput>;
};

export type AuditableRegistryRowSortInput = {
  approvalNumber?: InputMaybe<SortEnumType>;
  baselineName?: InputMaybe<SortEnumType>;
  brand?: InputMaybe<SortEnumType>;
  checksumMethod?: InputMaybe<SortEnumType>;
  complianceAssessmentReport?: InputMaybe<SortEnumType>;
  consumerSoftwareVersion?: InputMaybe<SortEnumType>;
  createdDate?: InputMaybe<SortEnumType>;
  ecuCorePartNumber?: InputMaybe<SortEnumType>;
  ecuCoreSupplierPartNumber?: InputMaybe<SortEnumType>;
  ecuHomologationNumber?: InputMaybe<SortEnumType>;
  ecuPartNumber?: InputMaybe<SortEnumType>;
  ecuShortName?: InputMaybe<SortEnumType>;
  kdpPartType?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  regulation?: InputMaybe<SortEnumType>;
  rxsWin?: InputMaybe<SortEnumType>;
  softwarePackageName?: InputMaybe<SortEnumType>;
  softwarePackageNameId?: InputMaybe<SortEnumType>;
  softwarePartNumber?: InputMaybe<SortEnumType>;
  softwareSupplierPartNumber?: InputMaybe<SortEnumType>;
  softwareType?: InputMaybe<SortEnumType>;
  systemDescription?: InputMaybe<SortEnumType>;
  vbfChecksum?: InputMaybe<SortEnumType>;
  vehicleSpec?: InputMaybe<SortEnumType>;
  version?: InputMaybe<SortEnumType>;
};

export type Baseline = {
  __typename?: 'Baseline';
  baselineId: Scalars['String'];
  baselineName: Scalars['String'];
  crbApprovalDate: Scalars['String'];
  createdDate: Scalars['DateTime'];
  hardware: Array<Hardware>;
  previousBaselineId: Scalars['String'];
};

export type BaselineRequestPayload = {
  __typename?: 'BaselineRequestPayload';
  nextWorkflowStage: WorkflowStage;
  processedWorkflowStage: WorkflowStage;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type ConfigurationParameterValues = {
  __typename?: 'ConfigurationParameterValues';
  baselines?: Maybe<Array<Baseline>>;
  variableCode: Scalars['String'];
  variableValue: Scalars['String'];
  variantExpressions?: Maybe<Array<VariantExpressions>>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type DeclarationRow = {
  __typename?: 'DeclarationRow';
  approvalNumber: Scalars['String'];
  date: Scalars['DateTime'];
  rxsWin: Scalars['String'];
  softwarePartNumber: Scalars['String'];
};

export type DeclarationRowFilterInput = {
  and?: InputMaybe<Array<DeclarationRowFilterInput>>;
  approvalNumber?: InputMaybe<StringOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DeclarationRowFilterInput>>;
  rxsWin?: InputMaybe<StringOperationFilterInput>;
  softwarePartNumber?: InputMaybe<StringOperationFilterInput>;
};

export type DeclarationRowSortInput = {
  approvalNumber?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  rxsWin?: InputMaybe<SortEnumType>;
  softwarePartNumber?: InputMaybe<SortEnumType>;
};

export type DeleteRxsWinInput = {
  approvalNumber: Scalars['String'];
  name: Scalars['String'];
};

export type DeliveryAssemblyInformation = {
  __typename?: 'DeliveryAssemblyInformation';
  ecuDeliveryPartNumber: Scalars['String'];
  homologationNumber: Scalars['String'];
};

export type DropdownData = {
  __typename?: 'DropdownData';
  brand: Scalars['String'];
  regulation: Scalars['String'];
  rxsWin: Scalars['String'];
  typeApprovals: Array<Scalars['String']>;
};

export type DropdownDataFilterInput = {
  and?: InputMaybe<Array<DropdownDataFilterInput>>;
  brand?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DropdownDataFilterInput>>;
  regulation?: InputMaybe<StringOperationFilterInput>;
  rxsWin?: InputMaybe<StringOperationFilterInput>;
  typeApprovals?: InputMaybe<ListStringOperationFilterInput>;
};

export type DropdownDataSortInput = {
  brand?: InputMaybe<SortEnumType>;
  regulation?: InputMaybe<SortEnumType>;
  rxsWin?: InputMaybe<SortEnumType>;
};

export type GenericProductModule = {
  __typename?: 'GenericProductModule';
  description: Scalars['String'];
};

export type GetAuditableRegistryInput = {
  brands: Array<Scalars['String']>;
  regulations: Array<Scalars['String']>;
  rxsWin?: InputMaybe<Scalars['String']>;
  typeApproval?: InputMaybe<Scalars['String']>;
};

export type Hardware = {
  __typename?: 'Hardware';
  assemblyInformation: Array<DeliveryAssemblyInformation>;
  baseline: Baseline;
  ecuShortName: Scalars['String'];
  kdpPartType: Scalars['String'];
  partNumber: Scalars['String'];
  software: Array<Software>;
};

export type ListFilterInputTypeOfRegulatoryRequirementFilterInput = {
  all?: InputMaybe<RegulatoryRequirementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RegulatoryRequirementFilterInput>;
  some?: InputMaybe<RegulatoryRequirementFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBaseline: BaselineRequestPayload;
  addPnoToHardwareSoftwarePart: BaselineRequestPayload;
  addRxsWin: RxsWinRequestPayload;
  deleteRxsWin: RxsWinRequestPayload;
  processPrinsResponse: PrinsResponsePayload;
  updateRxsWin: RxsWinRequestPayload;
};


export type MutationAddBaselineArgs = {
  input: AddBaselineInput;
};


export type MutationAddPnoToHardwareSoftwarePartArgs = {
  fileName: Scalars['String'];
};


export type MutationAddRxsWinArgs = {
  input: AddRxsWinInput;
};


export type MutationDeleteRxsWinArgs = {
  input: DeleteRxsWinInput;
};


export type MutationProcessPrinsResponseArgs = {
  input: PrinsResponseInput;
};


export type MutationUpdateRxsWinArgs = {
  input: UpdateRxsWinInput;
};

export type PrinsResponseInput = {
  fileName: Scalars['String'];
};

export type PrinsResponsePayload = {
  __typename?: 'PrinsResponsePayload';
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  auditableRegistryRows: Array<AuditableRegistryRow>;
  auditableRegistryRowsForSoftware: Array<AuditableRegistryRow>;
  auditableRegistryRowsForVin: Array<AuditableRegistryRow>;
  declarationRows: Array<DeclarationRow>;
  dropdownData: Array<DropdownData>;
  regulations: Array<Regulation>;
  user: User;
};


export type QueryAuditableRegistryRowsArgs = {
  input: GetAuditableRegistryInput;
  order?: InputMaybe<Array<AuditableRegistryRowSortInput>>;
  where?: InputMaybe<AuditableRegistryRowFilterInput>;
};


export type QueryAuditableRegistryRowsForSoftwareArgs = {
  order?: InputMaybe<Array<AuditableRegistryRowSortInput>>;
  softwarePartNumber: Scalars['String'];
  where?: InputMaybe<AuditableRegistryRowFilterInput>;
};


export type QueryAuditableRegistryRowsForVinArgs = {
  order?: InputMaybe<Array<AuditableRegistryRowSortInput>>;
  vin: Scalars['String'];
  where?: InputMaybe<AuditableRegistryRowFilterInput>;
};


export type QueryDeclarationRowsArgs = {
  order?: InputMaybe<Array<DeclarationRowSortInput>>;
  where?: InputMaybe<DeclarationRowFilterInput>;
};


export type QueryDropdownDataArgs = {
  order?: InputMaybe<Array<DropdownDataSortInput>>;
  where?: InputMaybe<DropdownDataFilterInput>;
};


export type QueryRegulationsArgs = {
  order?: InputMaybe<Array<RegulationSortInput>>;
  where?: InputMaybe<RegulationFilterInput>;
};


export type QueryUserArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};

export type Regulation = {
  __typename?: 'Regulation';
  isSums: Scalars['Boolean'];
  market: Scalars['String'];
  marketAndRegulation: Scalars['String'];
  regulationNumber: Scalars['String'];
  regulatoryRequirements: Array<RegulatoryRequirement>;
};

export type RegulationFilterInput = {
  and?: InputMaybe<Array<RegulationFilterInput>>;
  isSums?: InputMaybe<BooleanOperationFilterInput>;
  market?: InputMaybe<StringOperationFilterInput>;
  marketAndRegulation?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RegulationFilterInput>>;
  regulationNumber?: InputMaybe<StringOperationFilterInput>;
  regulatoryRequirements?: InputMaybe<ListFilterInputTypeOfRegulatoryRequirementFilterInput>;
};

export type RegulationSortInput = {
  isSums?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  marketAndRegulation?: InputMaybe<SortEnumType>;
  regulationNumber?: InputMaybe<SortEnumType>;
};

export type RegulatoryRequirement = {
  __typename?: 'RegulatoryRequirement';
  legalRegulationId: Scalars['String'];
  name: Scalars['String'];
  regulation: Regulation;
};

export type RegulatoryRequirementFilterInput = {
  and?: InputMaybe<Array<RegulatoryRequirementFilterInput>>;
  legalRegulationId?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RegulatoryRequirementFilterInput>>;
  regulation?: InputMaybe<RegulationFilterInput>;
};

export type RxsWin = {
  __typename?: 'RxsWin';
  brand: Brand;
  createdDate: Scalars['DateTime'];
  name: Scalars['String'];
  regulation: Regulation;
  softwarePackages: Array<SoftwarePackage>;
  typeApprovals: Array<TypeApproval>;
};

export type RxsWinRequestPayload = {
  __typename?: 'RxsWinRequestPayload';
  rxsWin: RxsWin;
};

export type Software = {
  __typename?: 'Software';
  checksumMethod: Scalars['String'];
  hardware: Hardware;
  partNumber: Scalars['String'];
  softwarePackages: Array<SoftwarePackage>;
  variantExpressions: Array<VariantExpressions>;
  vbfChecksum: Scalars['String'];
};

export type SoftwarePackage = {
  __typename?: 'SoftwarePackage';
  genericProductModule: GenericProductModule;
  name: Scalars['String'];
  packageId?: Maybe<Scalars['Int']>;
  regulatoryRequirements: Array<RegulatoryRequirement>;
  rxsWin: Array<RxsWin>;
  software: Software;
  version: Scalars['String'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TypeApproval = {
  __typename?: 'TypeApproval';
  approvalNumber: Scalars['String'];
  approvers: Array<Approver>;
  authority: Scalars['String'];
  createdDate: Scalars['DateTime'];
  directive: Scalars['String'];
  extension: Scalars['String'];
  finalized: Scalars['Boolean'];
  serialNumber: Scalars['String'];
  variants: Array<Variant>;
};

export type UpdateRxsWinInput = {
  approvalNumberInformation: ApprovalNumberInformationInput;
  approvers?: InputMaybe<Array<Scalars['String']>>;
  finalized: Scalars['Boolean'];
  rxsWinName: Scalars['String'];
  variants?: InputMaybe<Array<VariantInput>>;
};

export type User = {
  __typename?: 'User';
  accessLevel: AccessLevel;
};

export type UserFilterInput = {
  accessLevel?: InputMaybe<AccessLevelOperationFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  or?: InputMaybe<Array<UserFilterInput>>;
};

export type UserSortInput = {
  accessLevel?: InputMaybe<SortEnumType>;
};

export type Variant = {
  __typename?: 'Variant';
  engine: Scalars['String'];
  hbat: Scalars['String'];
  structureWeek: Scalars['Int'];
  transmission: Scalars['String'];
  typeCode: Scalars['String'];
  variantExpressions?: Maybe<Array<VariantExpressions>>;
};

export type VariantExpressions = {
  __typename?: 'VariantExpressions';
  configurationParameterValues: Array<ConfigurationParameterValues>;
  software: Software;
};

export type VariantInput = {
  engine: Scalars['String'];
  hbat?: InputMaybe<Scalars['String']>;
  structureWeek?: InputMaybe<Scalars['Int']>;
  transmission: Scalars['String'];
  typeCode: Scalars['String'];
};

export enum WorkflowStage {
  CarWeaver = 'CAR_WEAVER',
  Completed = 'COMPLETED',
  ConfigHub = 'CONFIG_HUB',
  Error = 'ERROR',
  Initiated = 'INITIATED',
  Kdp = 'KDP',
  NoakRequest = 'NOAK_REQUEST',
  NoakResponse = 'NOAK_RESPONSE',
  Pie = 'PIE',
  SoftwareTraceHub = 'SOFTWARE_TRACE_HUB',
  TeamCentre = 'TEAM_CENTRE'
}

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', accessLevel: AccessLevel } };

export type ExternalFieldsFragment = { __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null };

export type InternalFieldsFragment = { __typename?: 'AuditableRegistryRow', softwareSupplierPartNumber?: string | null, softwarePackageName?: string | null, ecuPartNumber?: string | null, ecuHomologationNumber?: string | null, ecuCorePartNumber: string, ecuCoreSupplierPartNumber?: string | null, vehicleSpec?: string | null, complianceAssessmentReport?: string | null, baselineName?: string | null };

export type GetInternalAuditableRegistryRowsQueryVariables = Exact<{
  brand: Array<Scalars['String']> | Scalars['String'];
  regulation: Array<Scalars['String']> | Scalars['String'];
  typeApprovals?: InputMaybe<Scalars['String']>;
  rxsWin?: InputMaybe<Scalars['String']>;
}>;


export type GetInternalAuditableRegistryRowsQuery = { __typename?: 'Query', auditableRegistryRows: Array<{ __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null, softwareSupplierPartNumber?: string | null, softwarePackageName?: string | null, ecuPartNumber?: string | null, ecuHomologationNumber?: string | null, ecuCorePartNumber: string, ecuCoreSupplierPartNumber?: string | null, vehicleSpec?: string | null, complianceAssessmentReport?: string | null, baselineName?: string | null }> };

export type GetInternalAuditableRegistryRowsByVinQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type GetInternalAuditableRegistryRowsByVinQuery = { __typename?: 'Query', auditableRegistryRowsForVin: Array<{ __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null, softwareSupplierPartNumber?: string | null, softwarePackageName?: string | null, ecuPartNumber?: string | null, ecuHomologationNumber?: string | null, ecuCorePartNumber: string, ecuCoreSupplierPartNumber?: string | null, vehicleSpec?: string | null, complianceAssessmentReport?: string | null, baselineName?: string | null }> };

export type GetInternalAuditableRegistryRowsBySwPartNumberQueryVariables = Exact<{
  softwarePartNumber: Scalars['String'];
}>;


export type GetInternalAuditableRegistryRowsBySwPartNumberQuery = { __typename?: 'Query', auditableRegistryRowsForSoftware: Array<{ __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null, softwareSupplierPartNumber?: string | null, softwarePackageName?: string | null, ecuPartNumber?: string | null, ecuHomologationNumber?: string | null, ecuCorePartNumber: string, ecuCoreSupplierPartNumber?: string | null, vehicleSpec?: string | null, complianceAssessmentReport?: string | null, baselineName?: string | null }> };

export type GetExternalAuditableRegistryRowsQueryVariables = Exact<{
  brand: Array<Scalars['String']> | Scalars['String'];
  regulation: Array<Scalars['String']> | Scalars['String'];
  rxsWin?: InputMaybe<Scalars['String']>;
  typeApprovals?: InputMaybe<Scalars['String']>;
}>;


export type GetExternalAuditableRegistryRowsQuery = { __typename?: 'Query', auditableRegistryRows: Array<{ __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null }> };

export type GetExternalAuditableRegistryRowsByVinQueryVariables = Exact<{
  vin: Scalars['String'];
}>;


export type GetExternalAuditableRegistryRowsByVinQuery = { __typename?: 'Query', auditableRegistryRowsForVin: Array<{ __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null }> };

export type GetExternalAuditableRegistryRowsBySwPartNumberQueryVariables = Exact<{
  softwarePartNumber: Scalars['String'];
}>;


export type GetExternalAuditableRegistryRowsBySwPartNumberQuery = { __typename?: 'Query', auditableRegistryRowsForSoftware: Array<{ __typename?: 'AuditableRegistryRow', market: string, rxsWin: string, approvalNumber: string, softwarePartNumber: string, ecuShortName: string, regulation: string, brand: string, createdDate: any, kdpPartType?: string | null, softwareType?: string | null, consumerSoftwareVersion?: string | null, checksumMethod?: string | null, vbfChecksum?: string | null, softwarePackageNameId?: string | null, version?: string | null, systemDescription?: string | null }> };

export type GetDropdownDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDropdownDataQuery = { __typename?: 'Query', dropdownData: Array<{ __typename?: 'DropdownData', rxsWin: string, regulation: string, brand: string, typeApprovals: Array<string> }> };

export type GetDeclarationsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeclarationsDataQuery = { __typename?: 'Query', declarationRows: Array<{ __typename?: 'DeclarationRow', rxsWin: string, approvalNumber: string, softwarePartNumber: string, date: any }> };

export const ExternalFieldsFragmentDoc = gql`
    fragment ExternalFields on AuditableRegistryRow {
  market
  rxsWin
  approvalNumber
  softwarePartNumber
  ecuShortName
  regulation
  brand
  createdDate
  kdpPartType
  softwareType
  consumerSoftwareVersion
  checksumMethod
  vbfChecksum
  softwarePackageNameId
  version
  systemDescription
}
    `;
export const InternalFieldsFragmentDoc = gql`
    fragment InternalFields on AuditableRegistryRow {
  softwareSupplierPartNumber
  softwarePackageName
  ecuPartNumber
  ecuHomologationNumber
  ecuCorePartNumber
  ecuCoreSupplierPartNumber
  vehicleSpec
  complianceAssessmentReport
  baselineName
}
    `;
export const GetUserDocument = gql`
    query GetUser {
  user {
    accessLevel
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetInternalAuditableRegistryRowsDocument = gql`
    query GetInternalAuditableRegistryRows($brand: [String!]!, $regulation: [String!]!, $typeApprovals: String, $rxsWin: String) {
  auditableRegistryRows(
    input: {brands: $brand, regulations: $regulation, rxsWin: $rxsWin, typeApproval: $typeApprovals}
  ) {
    ...ExternalFields
    ...InternalFields
  }
}
    ${ExternalFieldsFragmentDoc}
${InternalFieldsFragmentDoc}`;

/**
 * __useGetInternalAuditableRegistryRowsQuery__
 *
 * To run a query within a React component, call `useGetInternalAuditableRegistryRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalAuditableRegistryRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalAuditableRegistryRowsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      regulation: // value for 'regulation'
 *      typeApprovals: // value for 'typeApprovals'
 *      rxsWin: // value for 'rxsWin'
 *   },
 * });
 */
export function useGetInternalAuditableRegistryRowsQuery(baseOptions: Apollo.QueryHookOptions<GetInternalAuditableRegistryRowsQuery, GetInternalAuditableRegistryRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalAuditableRegistryRowsQuery, GetInternalAuditableRegistryRowsQueryVariables>(GetInternalAuditableRegistryRowsDocument, options);
      }
export function useGetInternalAuditableRegistryRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalAuditableRegistryRowsQuery, GetInternalAuditableRegistryRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalAuditableRegistryRowsQuery, GetInternalAuditableRegistryRowsQueryVariables>(GetInternalAuditableRegistryRowsDocument, options);
        }
export type GetInternalAuditableRegistryRowsQueryHookResult = ReturnType<typeof useGetInternalAuditableRegistryRowsQuery>;
export type GetInternalAuditableRegistryRowsLazyQueryHookResult = ReturnType<typeof useGetInternalAuditableRegistryRowsLazyQuery>;
export type GetInternalAuditableRegistryRowsQueryResult = Apollo.QueryResult<GetInternalAuditableRegistryRowsQuery, GetInternalAuditableRegistryRowsQueryVariables>;
export const GetInternalAuditableRegistryRowsByVinDocument = gql`
    query GetInternalAuditableRegistryRowsByVin($vin: String!) {
  auditableRegistryRowsForVin(vin: $vin) {
    ...ExternalFields
    ...InternalFields
  }
}
    ${ExternalFieldsFragmentDoc}
${InternalFieldsFragmentDoc}`;

/**
 * __useGetInternalAuditableRegistryRowsByVinQuery__
 *
 * To run a query within a React component, call `useGetInternalAuditableRegistryRowsByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalAuditableRegistryRowsByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalAuditableRegistryRowsByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useGetInternalAuditableRegistryRowsByVinQuery(baseOptions: Apollo.QueryHookOptions<GetInternalAuditableRegistryRowsByVinQuery, GetInternalAuditableRegistryRowsByVinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalAuditableRegistryRowsByVinQuery, GetInternalAuditableRegistryRowsByVinQueryVariables>(GetInternalAuditableRegistryRowsByVinDocument, options);
      }
export function useGetInternalAuditableRegistryRowsByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalAuditableRegistryRowsByVinQuery, GetInternalAuditableRegistryRowsByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalAuditableRegistryRowsByVinQuery, GetInternalAuditableRegistryRowsByVinQueryVariables>(GetInternalAuditableRegistryRowsByVinDocument, options);
        }
export type GetInternalAuditableRegistryRowsByVinQueryHookResult = ReturnType<typeof useGetInternalAuditableRegistryRowsByVinQuery>;
export type GetInternalAuditableRegistryRowsByVinLazyQueryHookResult = ReturnType<typeof useGetInternalAuditableRegistryRowsByVinLazyQuery>;
export type GetInternalAuditableRegistryRowsByVinQueryResult = Apollo.QueryResult<GetInternalAuditableRegistryRowsByVinQuery, GetInternalAuditableRegistryRowsByVinQueryVariables>;
export const GetInternalAuditableRegistryRowsBySwPartNumberDocument = gql`
    query GetInternalAuditableRegistryRowsBySWPartNumber($softwarePartNumber: String!) {
  auditableRegistryRowsForSoftware(softwarePartNumber: $softwarePartNumber) {
    ...ExternalFields
    ...InternalFields
  }
}
    ${ExternalFieldsFragmentDoc}
${InternalFieldsFragmentDoc}`;

/**
 * __useGetInternalAuditableRegistryRowsBySwPartNumberQuery__
 *
 * To run a query within a React component, call `useGetInternalAuditableRegistryRowsBySwPartNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalAuditableRegistryRowsBySwPartNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalAuditableRegistryRowsBySwPartNumberQuery({
 *   variables: {
 *      softwarePartNumber: // value for 'softwarePartNumber'
 *   },
 * });
 */
export function useGetInternalAuditableRegistryRowsBySwPartNumberQuery(baseOptions: Apollo.QueryHookOptions<GetInternalAuditableRegistryRowsBySwPartNumberQuery, GetInternalAuditableRegistryRowsBySwPartNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalAuditableRegistryRowsBySwPartNumberQuery, GetInternalAuditableRegistryRowsBySwPartNumberQueryVariables>(GetInternalAuditableRegistryRowsBySwPartNumberDocument, options);
      }
export function useGetInternalAuditableRegistryRowsBySwPartNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalAuditableRegistryRowsBySwPartNumberQuery, GetInternalAuditableRegistryRowsBySwPartNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalAuditableRegistryRowsBySwPartNumberQuery, GetInternalAuditableRegistryRowsBySwPartNumberQueryVariables>(GetInternalAuditableRegistryRowsBySwPartNumberDocument, options);
        }
export type GetInternalAuditableRegistryRowsBySwPartNumberQueryHookResult = ReturnType<typeof useGetInternalAuditableRegistryRowsBySwPartNumberQuery>;
export type GetInternalAuditableRegistryRowsBySwPartNumberLazyQueryHookResult = ReturnType<typeof useGetInternalAuditableRegistryRowsBySwPartNumberLazyQuery>;
export type GetInternalAuditableRegistryRowsBySwPartNumberQueryResult = Apollo.QueryResult<GetInternalAuditableRegistryRowsBySwPartNumberQuery, GetInternalAuditableRegistryRowsBySwPartNumberQueryVariables>;
export const GetExternalAuditableRegistryRowsDocument = gql`
    query GetExternalAuditableRegistryRows($brand: [String!]!, $regulation: [String!]!, $rxsWin: String, $typeApprovals: String) {
  auditableRegistryRows(
    input: {brands: $brand, regulations: $regulation, rxsWin: $rxsWin, typeApproval: $typeApprovals}
  ) {
    ...ExternalFields
  }
}
    ${ExternalFieldsFragmentDoc}`;

/**
 * __useGetExternalAuditableRegistryRowsQuery__
 *
 * To run a query within a React component, call `useGetExternalAuditableRegistryRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalAuditableRegistryRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalAuditableRegistryRowsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      regulation: // value for 'regulation'
 *      rxsWin: // value for 'rxsWin'
 *      typeApprovals: // value for 'typeApprovals'
 *   },
 * });
 */
export function useGetExternalAuditableRegistryRowsQuery(baseOptions: Apollo.QueryHookOptions<GetExternalAuditableRegistryRowsQuery, GetExternalAuditableRegistryRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalAuditableRegistryRowsQuery, GetExternalAuditableRegistryRowsQueryVariables>(GetExternalAuditableRegistryRowsDocument, options);
      }
export function useGetExternalAuditableRegistryRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalAuditableRegistryRowsQuery, GetExternalAuditableRegistryRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalAuditableRegistryRowsQuery, GetExternalAuditableRegistryRowsQueryVariables>(GetExternalAuditableRegistryRowsDocument, options);
        }
export type GetExternalAuditableRegistryRowsQueryHookResult = ReturnType<typeof useGetExternalAuditableRegistryRowsQuery>;
export type GetExternalAuditableRegistryRowsLazyQueryHookResult = ReturnType<typeof useGetExternalAuditableRegistryRowsLazyQuery>;
export type GetExternalAuditableRegistryRowsQueryResult = Apollo.QueryResult<GetExternalAuditableRegistryRowsQuery, GetExternalAuditableRegistryRowsQueryVariables>;
export const GetExternalAuditableRegistryRowsByVinDocument = gql`
    query GetExternalAuditableRegistryRowsByVin($vin: String!) {
  auditableRegistryRowsForVin(vin: $vin) {
    ...ExternalFields
  }
}
    ${ExternalFieldsFragmentDoc}`;

/**
 * __useGetExternalAuditableRegistryRowsByVinQuery__
 *
 * To run a query within a React component, call `useGetExternalAuditableRegistryRowsByVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalAuditableRegistryRowsByVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalAuditableRegistryRowsByVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useGetExternalAuditableRegistryRowsByVinQuery(baseOptions: Apollo.QueryHookOptions<GetExternalAuditableRegistryRowsByVinQuery, GetExternalAuditableRegistryRowsByVinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalAuditableRegistryRowsByVinQuery, GetExternalAuditableRegistryRowsByVinQueryVariables>(GetExternalAuditableRegistryRowsByVinDocument, options);
      }
export function useGetExternalAuditableRegistryRowsByVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalAuditableRegistryRowsByVinQuery, GetExternalAuditableRegistryRowsByVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalAuditableRegistryRowsByVinQuery, GetExternalAuditableRegistryRowsByVinQueryVariables>(GetExternalAuditableRegistryRowsByVinDocument, options);
        }
export type GetExternalAuditableRegistryRowsByVinQueryHookResult = ReturnType<typeof useGetExternalAuditableRegistryRowsByVinQuery>;
export type GetExternalAuditableRegistryRowsByVinLazyQueryHookResult = ReturnType<typeof useGetExternalAuditableRegistryRowsByVinLazyQuery>;
export type GetExternalAuditableRegistryRowsByVinQueryResult = Apollo.QueryResult<GetExternalAuditableRegistryRowsByVinQuery, GetExternalAuditableRegistryRowsByVinQueryVariables>;
export const GetExternalAuditableRegistryRowsBySwPartNumberDocument = gql`
    query GetExternalAuditableRegistryRowsBySWPartNumber($softwarePartNumber: String!) {
  auditableRegistryRowsForSoftware(softwarePartNumber: $softwarePartNumber) {
    ...ExternalFields
  }
}
    ${ExternalFieldsFragmentDoc}`;

/**
 * __useGetExternalAuditableRegistryRowsBySwPartNumberQuery__
 *
 * To run a query within a React component, call `useGetExternalAuditableRegistryRowsBySwPartNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalAuditableRegistryRowsBySwPartNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalAuditableRegistryRowsBySwPartNumberQuery({
 *   variables: {
 *      softwarePartNumber: // value for 'softwarePartNumber'
 *   },
 * });
 */
export function useGetExternalAuditableRegistryRowsBySwPartNumberQuery(baseOptions: Apollo.QueryHookOptions<GetExternalAuditableRegistryRowsBySwPartNumberQuery, GetExternalAuditableRegistryRowsBySwPartNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalAuditableRegistryRowsBySwPartNumberQuery, GetExternalAuditableRegistryRowsBySwPartNumberQueryVariables>(GetExternalAuditableRegistryRowsBySwPartNumberDocument, options);
      }
export function useGetExternalAuditableRegistryRowsBySwPartNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalAuditableRegistryRowsBySwPartNumberQuery, GetExternalAuditableRegistryRowsBySwPartNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalAuditableRegistryRowsBySwPartNumberQuery, GetExternalAuditableRegistryRowsBySwPartNumberQueryVariables>(GetExternalAuditableRegistryRowsBySwPartNumberDocument, options);
        }
export type GetExternalAuditableRegistryRowsBySwPartNumberQueryHookResult = ReturnType<typeof useGetExternalAuditableRegistryRowsBySwPartNumberQuery>;
export type GetExternalAuditableRegistryRowsBySwPartNumberLazyQueryHookResult = ReturnType<typeof useGetExternalAuditableRegistryRowsBySwPartNumberLazyQuery>;
export type GetExternalAuditableRegistryRowsBySwPartNumberQueryResult = Apollo.QueryResult<GetExternalAuditableRegistryRowsBySwPartNumberQuery, GetExternalAuditableRegistryRowsBySwPartNumberQueryVariables>;
export const GetDropdownDataDocument = gql`
    query GetDropdownData {
  dropdownData {
    rxsWin
    regulation
    brand
    typeApprovals
  }
}
    `;

/**
 * __useGetDropdownDataQuery__
 *
 * To run a query within a React component, call `useGetDropdownDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDropdownDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDropdownDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDropdownDataQuery(baseOptions?: Apollo.QueryHookOptions<GetDropdownDataQuery, GetDropdownDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDropdownDataQuery, GetDropdownDataQueryVariables>(GetDropdownDataDocument, options);
      }
export function useGetDropdownDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDropdownDataQuery, GetDropdownDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDropdownDataQuery, GetDropdownDataQueryVariables>(GetDropdownDataDocument, options);
        }
export type GetDropdownDataQueryHookResult = ReturnType<typeof useGetDropdownDataQuery>;
export type GetDropdownDataLazyQueryHookResult = ReturnType<typeof useGetDropdownDataLazyQuery>;
export type GetDropdownDataQueryResult = Apollo.QueryResult<GetDropdownDataQuery, GetDropdownDataQueryVariables>;
export const GetDeclarationsDataDocument = gql`
    query GetDeclarationsData {
  declarationRows {
    rxsWin
    approvalNumber
    softwarePartNumber
    date
  }
}
    `;

/**
 * __useGetDeclarationsDataQuery__
 *
 * To run a query within a React component, call `useGetDeclarationsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeclarationsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeclarationsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeclarationsDataQuery(baseOptions?: Apollo.QueryHookOptions<GetDeclarationsDataQuery, GetDeclarationsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeclarationsDataQuery, GetDeclarationsDataQueryVariables>(GetDeclarationsDataDocument, options);
      }
export function useGetDeclarationsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeclarationsDataQuery, GetDeclarationsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeclarationsDataQuery, GetDeclarationsDataQueryVariables>(GetDeclarationsDataDocument, options);
        }
export type GetDeclarationsDataQueryHookResult = ReturnType<typeof useGetDeclarationsDataQuery>;
export type GetDeclarationsDataLazyQueryHookResult = ReturnType<typeof useGetDeclarationsDataLazyQuery>;
export type GetDeclarationsDataQueryResult = Apollo.QueryResult<GetDeclarationsDataQuery, GetDeclarationsDataQueryVariables>;