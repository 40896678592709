export const getBrandOptions = (data: any) => {
    return [...new Set(data?.dropdownData.map((record: any) => record.brand))];
  };
  
  export const handleBrandDependencies = (
    filters: any,
    setFilters: any,
    getRegulationOptions: any,
    getTypeApprovalOptions: any,
    getRxsWinOptions: any,
    newValue: string[]
  ) => {
    let newFilters = {
      regulation:
        filters.regulation && !getRegulationOptions(newValue).some((regulation: any) => filters.regulation.includes(regulation))
          ? []
          : filters.regulation.filter((regulation: any) => getRegulationOptions(newValue).includes(regulation)),
      typeApprovals:
        filters.typeApprovals &&
        !getTypeApprovalOptions(newValue, filters.regulation).includes(filters.typeApprovals)
          ? ''
          : filters.typeApprovals,
      rxsWin:
        filters.rxsWin && !getRxsWinOptions(newValue, filters.regulation).includes(filters.rxsWin)
          ? ''
          : filters.rxsWin,
    };
  
    if (newValue.length < 1) {
      newFilters = {
        regulation: [],
        typeApprovals: '',
        rxsWin: '',
      };
    }
    setFilters((prevState: any) => ({
      ...prevState,
      ...newFilters,
    }));
  };