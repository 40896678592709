import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DeclerationsDataGrid, SelectableCards, ToggleTabs, DataGridHoverTooltip } from '~components';
import { FilteredRxsWinProps, SelectableCardsValues, ToggleTabsValues } from '~constants';
import {
  filterRxsWinByActiveTab,
  filterRxsWinBySelectedCard,
  getDeclarationsDatagrid,
} from '~helpers';
import { useGetDeclarationsDataQuery } from '~codegen/types-and-hooks';

export const Declarations = () => {
  const [activeTab, setActiveTab] = useState(ToggleTabsValues.LastSixWeeks);
  const [selectedCard, setSelectedCard] = useState(SelectableCardsValues.SWReleases);

  const {
    data: rxsWinData,
    loading: rxsWinLoading,
    error: rxsWinError,
  } = useGetDeclarationsDataQuery();

  const dateFilteredRxsWin =
    (!rxsWinLoading || !rxsWinError) && rxsWinData !== undefined
      ? filterRxsWinByActiveTab(rxsWinData.declarationRows, activeTab)
      : undefined;

  const cardFilteredRxsWin = filterRxsWinBySelectedCard(
    dateFilteredRxsWin,
    selectedCard,
  ) as FilteredRxsWinProps[];

  const { columnDefs, rowData } = getDeclarationsDatagrid(cardFilteredRxsWin, selectedCard);
  
 

  return (
    <Grid container>
      <Grid item xs={12} lg={4}>
        <Box sx={{ width: '100%', height: '100%', padding: '1rem' }}>
          <ToggleTabs setActiveTab={setActiveTab} activeTab={activeTab} />
          <SelectableCards
            loading={rxsWinLoading}
            rxsWin={dateFilteredRxsWin}
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <DeclerationsDataGrid
          loading={rxsWinLoading}
          formattedColumnDefs={columnDefs}
          formattedRowData={rowData}
        />
      </Grid>
    </Grid>
  );
};
