import { Box, SxProps} from '@mui/material';
import { Button } from '~components';

interface FilterButtonsProps {
  onClearClick: () => void;
  onSearchClick: () => void;
  clearDisabled: boolean;
  searchDisabled: boolean;
  boxStyle?: SxProps;
  clearButtonStyle?: SxProps;
  searchButtonStyle?: SxProps;
}

export const FilterButtons = ({
  onClearClick,
  onSearchClick,
  clearDisabled,
  searchDisabled,
  boxStyle,
  clearButtonStyle,
  searchButtonStyle,
}: FilterButtonsProps) => (
  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', ...boxStyle }}>
    <Button
      sx={{ width: '100%', mr: 1, ...clearButtonStyle }}
      data-testid="clearFiltersBtn"
      disabled={clearDisabled}
      onClick={onClearClick}
    >
      Clear
    </Button>
    <Button
      sx={{ ml: 1, width: '100%', ...searchButtonStyle }}
      variant="outlined"
      data-testid="filterBtn"
      disabled={searchDisabled}
      onClick={onSearchClick}
    >
      Search
    </Button>
  </Box>
);


export default FilterButtons;