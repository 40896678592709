import { Dispatch, SetStateAction } from 'react';
import { FormControl } from '@mui/material';
import { useGetDropdownDataQuery } from '~codegen/types-and-hooks';
import { FiltersProps } from '../AuditableRegister';
import { IFilterCommonProps } from '../types';
import { Autocomplete } from '~components';
import { FilterButtons } from '~components';	
import { getBrandOptions, handleBrandDependencies } from './FilterUtils/brandUtils';
import { getRegulationOptions, handleRegulationDependencies } from './FilterUtils/regulationUtils';
import { getTypeApprovalOptions } from './FilterUtils/typeApprovalUtils';
import { getRxsWinOptions } from './FilterUtils/rxswinUtils';


enum FilterKey {
  Brand = "brand",
  Regulation = "regulation",
  TypeApproval = "typeApprovals",
  Rxswin = "rxsWin",
}

interface IFilters extends IFilterCommonProps {
  setFilters: Dispatch<SetStateAction<FiltersProps>>;
  loading?: boolean;
}

const filterAllOptionsValue = 'All';

export const Filters = ({ filters, setFilters, getRxsWin, loading = false }: IFilters) => {
  const {
    loading: dropdownDataLoading,
    error: dropdownDataError,
    data,
  } = useGetDropdownDataQuery();

  const filtersOptionsHandler = (key: FilterKey, value: string[] | string | null) => {
    
    if (value?.includes(filterAllOptionsValue)) {
      switch (key) {
        case FilterKey.Regulation:
          value = getRegulationOptions(data, filters.brand) as string[];
          break;
        case FilterKey.TypeApproval:
          value = getTypeApprovalOptions(data, filters.brand, filters.regulation) as string[];
          break;
        case FilterKey.Rxswin:
          value = getRxsWinOptions(data, filters.brand, filters.regulation) as string[];
          break;
        default:
          value = '';
      }
    }
  
    setFilters((prevState: FiltersProps) => ({
      ...prevState,
      ...{ [key]: value ?? '' },
    }));
  };

  const onClearClickHandler = () => {
    setFilters(() => ({
      ...filters,
      brand: [],
      regulation: [],
      typeApprovals: '',
      rxsWin: '',
    }));
  };

  const onSearchClickHandler = () => {
    setFilters(() => ({
      ...filters,
      vin: '',
      softwarePartNumber: '',
    }));
    getRxsWin(filters);
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        label="Brand *"
        multiple
        id="brand"
        data-testid="brandFilter"
        onChange={(_e, newValue) => {
          filtersOptionsHandler(FilterKey.Brand, newValue);
          handleBrandDependencies(filters, setFilters, getRegulationOptions.bind(null, data), getTypeApprovalOptions.bind(null, data), getRxsWinOptions.bind(null, data), newValue as string[]);
        }}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getBrandOptions(data) as string[]}
        disabled={dropdownDataLoading || loading}
        value={filters.brand || null}
        disableCloseOnSelect
      />
      <Autocomplete
        sx={{ mt: 2 }}
        label="Regulation *"
        multiple
        id="legal-regulation"
        data-testid="regulationFilter"
        onChange={(_e, newValue) => {
          filtersOptionsHandler(FilterKey.Regulation, newValue);
          handleRegulationDependencies(filters, setFilters, getTypeApprovalOptions.bind(null, data), getRxsWinOptions.bind(null, data), newValue as string[]);
        }}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getRegulationOptions(data, filters.brand) as string[]}
        disabled={filters.brand.length < 1 || dropdownDataLoading || loading}
        value={filters.regulation || null}
        disableCloseOnSelect
      
      />
      <Autocomplete
        sx={{ mt: 2 }}
        label="Type Approval"
        id="type-approval"
        data-testid="typeApprovalFilter"
        onChange={(_e, newValue) => filtersOptionsHandler(FilterKey.TypeApproval, newValue)}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getTypeApprovalOptions(data, filters.brand, filters.regulation) as string[]}
        disabled={filters.brand.length < 1 || filters.regulation.length < 1 || dropdownDataLoading || loading}
        value={filters.typeApprovals || null}
        disableCloseOnSelect
      />
      <Autocomplete
        sx={{ mt: 2 }}
        label="RXSWIN"
        id="rxsWin"
        data-testid="rxsWinFilter"
        onChange={(_e, newValue) => filtersOptionsHandler(FilterKey.Rxswin, newValue)}
        loading={dropdownDataLoading || loading}
        options={dropdownDataError ? [] : getRxsWinOptions(data, filters.brand, filters.regulation) as string[]}
        disabled={filters.brand.length < 1 || filters.regulation.length < 1 || dropdownDataLoading || loading}
        value={filters.rxsWin || null}
        disableCloseOnSelect
      />
      <FilterButtons
        onClearClick={onClearClickHandler}
        onSearchClick={onSearchClickHandler}
        clearDisabled={filters.brand.length < 1 && filters.regulation.length < 1}
        searchDisabled={filters.brand.length < 1 || filters.regulation.length < 1}
      />
    </FormControl>
  );
};