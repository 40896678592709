import { CircularProgress, Typography, useTheme } from '@mui/material';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { WrapperCard } from '~components';

const Loader = () => {
  const theme = useTheme();
  return <CircularProgress color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'} />;
};

export const NoRowsOverlay = (
  params: INoRowsOverlayParams & { loading: boolean },
) => {
  const { loading } = params;
  return loading ? (
    <Loader />
  ) : (
    <WrapperCard>
      <Typography>
        {
          'No rows found.'}
      </Typography>
    </WrapperCard>
  );
};

export const LoadingOverlay = () => <Loader />;
