import { StatusPanelGridButtons, StatusPanelSearchBox } from '~components';
import { BaseDataGrid } from './GenericDataGrid';
import { ColDef, GridApi, StatusPanelDef} from 'ag-grid-community';

interface AuditableRegisterDataGridProps {
  formattedColumnDefs: ColDef[];
  formattedRowData?: Record<string, string>[] | null;
  loading: boolean;
  groupByValue?: string;
  autoGroupColumnDef?: ColDef;
}

export const AuditableRegisterDataGrid = ({
  formattedColumnDefs,
  formattedRowData,
  loading,
  groupByValue,
  autoGroupColumnDef,
}: AuditableRegisterDataGridProps) => {

  const statusPanels: StatusPanelDef[] = [
    {
      statusPanel: StatusPanelGridButtons,
      align: 'left',
      statusPanelParams: { gridApi: null},
    },
    {
      statusPanel: StatusPanelSearchBox,
      align: 'center',
      statusPanelParams: { gridApi: null },
    },
  ];

  const onSelectionChanged = (gridApi: GridApi) => {
      return
  };

  return (
    <BaseDataGrid
      formattedColumnDefs={formattedColumnDefs}
      formattedRowData={formattedRowData}
      loading={loading}
      groupByValue={groupByValue}
      autoGroupColumnDef={autoGroupColumnDef}
      statusPanels={statusPanels}
      onSelectionChanged={onSelectionChanged}
      tooltipComponentvalue={null}
      tooltipMouseTrackValue = {false}
      flexValue={0}
      resizeColumns = {true}

    />
  );
};