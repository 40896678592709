import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  AutocompleteProps,
  Checkbox,
  CircularProgress,
  Autocomplete as MUIAutocomplete,
  TextField,
  useTheme,
} from '@mui/material';


interface IAutocomplete<T>
  extends Omit<
    AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined | undefined>,
    'renderInput'
  > {
  label: string;
}


export const Autocomplete = <T extends any,>({ label, ...props }: IAutocomplete<T>) => {
  const theme = useTheme();
  const icon = <CheckBoxOutlineBlank fontSize='small'/>
  const checkedIcon = <CheckBox fontSize='small'/>
    return (
    <MUIAutocomplete
      sx={{
        mt: 1,
        '& .MuiTextField-root': {
          '& .MuiOutlinedInput-root.MuiInputBase-root': {
            paddingRight: `36px`,
          },
        },
      }}
      size="small"
      {...props}
    
      renderInput={(params) => (
        <TextField
          color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {String(option)}
        </li>
      )}
    />
  )};


  