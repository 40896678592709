import { StatusPanelSearchBox, DataGridHoverTooltip } from '~components';
import { BaseDataGrid } from './GenericDataGrid';
import { useNavigate } from 'react-router-dom';
import { ColDef, GridApi, StatusPanelDef } from 'ag-grid-community';

interface DeclerationsDataGridProps {
  formattedColumnDefs: ColDef[];
  formattedRowData?: Record<string, string>[] | null;
  loading: boolean;
  groupByValue?: string;
  autoGroupColumnDef?: ColDef;
}

// This is the grid that is being used in declarations view.

export const DeclerationsDataGrid = ({
  formattedColumnDefs,
  formattedRowData,
  loading,
  groupByValue,
  autoGroupColumnDef,
}: DeclerationsDataGridProps) => {

  const navigate = useNavigate();
   const onSelectionChanged = (gridApi: GridApi) => {
      const selectedRows = gridApi.getSelectedRows();
      if (!selectedRows.length) return;
      navigate(`/auditable-register`, {
        replace: false,
        state: { softwarePartNumber: selectedRows[0].softwarePartNumber },
      });
  };


  const statusPanels: StatusPanelDef[] = [
    {
      statusPanel: StatusPanelSearchBox,
      align: 'center',
      statusPanelParams: { gridApi: null },
    },
  ];

  return (
    <BaseDataGrid
      formattedColumnDefs={formattedColumnDefs}
      formattedRowData={formattedRowData}
      loading={loading}
      groupByValue={groupByValue}
      autoGroupColumnDef={autoGroupColumnDef}
      statusPanels={statusPanels}
      onSelectionChanged={onSelectionChanged}
      tooltipComponentvalue={DataGridHoverTooltip}
      tooltipMouseTrackValue = {true}
      flexValue={1}
      resizeColumns = {false}
    />
  );
};